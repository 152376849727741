import { useKeycloak } from '@react-keycloak/web'
import { Wrapper, TopMenu, Menu, Logout } from './styled'
import { useKeycloakLogout } from '../../shared/hooks/useKeycloakLogout'

export function UserInfo() {
	const { keycloak } = useKeycloak()
	const { logout } = useKeycloakLogout()
	return (
		<Wrapper>
			<TopMenu>
				<Menu>
					Вы авторизованы как: {keycloak.tokenParsed?.preferred_username || ''}
					<Logout onClick={logout}>Выйти</Logout>
				</Menu>
			</TopMenu>
		</Wrapper>
	)
}
