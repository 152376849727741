import { createTheme } from '@material-ui/core/styles'
import { StyleTheme } from './types'

export const theme: StyleTheme = {
	palette: {
		primary: '#00a1dc',
		secondary: '#000',
		text: '#343434',
		info: '#808080',
		background: '#F4F6F9',
		error: '#EC1A33',
	},
	spaces: {
		s: '8px',
		m: '16px',
		l: '24px',
		xl: '32px',
		xxl: '48px',
	},
	screenSizes: {
		minTablet: 768,
		minDesktop: 992,
		minLargeDesktop: 1200,
	},
	shadow: {
		base: '0px 4px 20px rgba(62, 83, 114, 0.08);',
	},
	shape: {
		borderRadius: '6px',
	},
}

export const muiTheme = createTheme({
	palette: {
		primary: {
			main: theme.palette.primary,
		},
		secondary: {
			main: theme.palette.secondary,
		},
		info: {
			main: theme.palette.info,
		},
		error: {
			main: theme.palette.error,
		},
		background: {
			default: theme.palette.background,
		},
		text: {
			primary: theme.palette.text,
		},
	},
	shape: {
		borderRadius: +theme.shape.borderRadius.replace('px', ''),
	},
})
