import { DateTime } from 'luxon'

export function requireNotNull<T>(value: T | undefined | null, message?: string): T {
	if (value === undefined || value === null) {
		throw new TypeError(message ?? 'Required value is not defined')
	}
	return value
}

/**
 * @param start
 * @param end
 * @example getRangeArr(1, 5) = [1, 2, 3, 4, 5]
 */
export const getRangeArr = (start: number, end: number): number[] => {
	let cursor = start
	// eslint-disable-next-line no-plusplus
	return [cursor, ...new Array(end - start).fill(null).map(() => ++cursor)]
}

export const formatISODate = (isoDate: string): string => {
	return DateTime.fromISO(isoDate).toLocaleString()
}

export const createSiteUrlByPath = (pagePath: string): string => {
	return `${window.__env__.SITE_URL}${pagePath}`
}

export const isDebug = window.__env__.ENVIRONMENT !== 'production'
