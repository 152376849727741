import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Wrapper, TopMenu, Menu, BodyWrapper, MenuNav, NavsWrapper, Header } from './styled'
import { routes } from '../../../routes'

type NavProps = {
	to: string
	children: React.ReactNode
}
function Nav({ to, children }: NavProps) {
	const location = useLocation()
	const history = useHistory()
	const onClickMenu = (e: React.MouseEvent) => {
		e.preventDefault()
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		history.push(e.currentTarget.getAttribute('href')!)
	}

	const isActive = location.pathname === to

	return (
		<MenuNav href={to} onClick={onClickMenu} isActive={isActive}>
			{children}
		</MenuNav>
	)
}

type PageLayoutProps = {
	title: string
	isNavsHidden?: boolean
	headerRightContent?: React.ReactNode
	children: React.ReactNode
}

export function PageLayout({ children, title, headerRightContent, isNavsHidden }: PageLayoutProps) {
	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Wrapper>
				<TopMenu>
					<Menu>
						<NavsWrapper>
							{!isNavsHidden && (
								<>
									<Nav to={routes.home}>Страницы</Nav>
									<Nav to={routes.components}>Компоненты</Nav>
									<Nav to={routes.documents}>Документы</Nav>
									<Nav to={routes.promoPages}>Промо страницы</Nav>
									<Nav to={routes.variables}>Переменные</Nav>
								</>
							)}
						</NavsWrapper>
					</Menu>
				</TopMenu>
				<BodyWrapper>
					<Header>
						<Typography variant="h3">{title}</Typography>
						{headerRightContent}
					</Header>
					<br />
					{children}
				</BodyWrapper>
			</Wrapper>
		</>
	)
}
