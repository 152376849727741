import styled, { css } from 'styled-components/macro'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;
	flex: 1;
`

export const TopMenu = styled.div`
	height: 50px;
	width: 100%;
	background: ${({ theme }) => theme.palette.text};
`

export const Menu = styled.div`
	padding-left: ${({ theme }) => theme.spaces.s};
	padding-right: ${({ theme }) => theme.spaces.s};
	display: flex;
	height: 100%;

	@media (min-width: 1200px) {
		padding-left: 0;
		padding-right: 0;
		min-width: 1200px;
		max-width: 1200px;
		margin: 0 auto;
	}
`

export const NavsWrapper = styled.div`
	display: inherit;
	height: 100%;
`

export const MenuNav = styled.a<{ isActive: boolean }>`
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 18px;
	font-weight: 400;
	text-decoration: none;
	color: #fff;
	text-align: center;
	padding-left: ${({ theme }) => theme.spaces.m};
	padding-right: ${({ theme }) => theme.spaces.m};
	position: relative;

	${({ isActive, theme }) =>
		isActive &&
		css`
			&:after {
				content: '';
				width: 100%;
				position: absolute;
				bottom: 0;
				height: 4px;
				left: 0;
				background: ${theme.palette.background};
			}
		`}
`

export const BodyWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-left: ${({ theme }) => theme.spaces.s};
	padding-right: ${({ theme }) => theme.spaces.s};
	padding-top: ${({ theme }) => theme.spaces.l};
	padding-bottom: ${({ theme }) => theme.spaces.l};

	@media (min-width: 1200px) {
		padding-left: 0;
		padding-right: 0;
		min-width: 1200px;
		max-width: 1200px;
		margin: 0 auto;
	}
`

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`
