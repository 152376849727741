import React, { createContext, useCallback, useContext, useState, useMemo } from 'react'
import { AlertDialog } from '../components/alert-dialog'

export interface ConfirmShowProps {
	onApply(): void
	dialogText?: string | React.ReactNode
}

export interface ConfirmContextInterface {
	show(confirmShowProps: ConfirmShowProps): void
}

const Context = createContext<ConfirmContextInterface | undefined>(undefined)

interface ConfirmDialogProviderProps {
	children?: React.ReactNode
}

export function ConfirmDialogProvider({ children }: ConfirmDialogProviderProps) {
	const [dialogParams, setdialogParams] = useState<ConfirmShowProps | null>(null)

	const handleClose = () => {
		setdialogParams(null)
	}

	const handleShow = useCallback((data: ConfirmShowProps) => {
		setdialogParams(data)
	}, [])

	const handleApply = () => {
		dialogParams?.onApply()
		handleClose()
	}

	const value = useMemo<ConfirmContextInterface>(
		() => ({
			show: handleShow,
		}),
		[handleShow],
	)

	return (
		<Context.Provider value={value}>
			<AlertDialog
				onSuccess={handleApply}
				open={!!dialogParams}
				onClose={handleClose}
				dialogText={dialogParams?.dialogText}
			/>
			{children}
		</Context.Provider>
	)
}

export const useConfirmDialog = () => useContext(Context) as ConfirmContextInterface
