import { useKeycloak } from '@react-keycloak/web'

interface ReturnType {
	logout: () => Promise<void>
}

export const useKeycloakLogout = (): ReturnType => {
	const { keycloak } = useKeycloak()
	const handleLogout = async () => {
		await keycloak.logout()
		// eslint-disable-next-line no-restricted-globals
		location.reload()
	}

	return {
		logout: handleLogout,
	}
}
