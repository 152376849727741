import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  #root {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`
