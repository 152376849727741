import styled from 'styled-components/macro'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const TopMenu = styled.div`
	height: 15px;
	width: 100%;
	background: ${({ theme }) => theme.palette.text};
	color: #fff;
	text-align: right;
`

export const Menu = styled.div`
	padding-left: ${({ theme }) => theme.spaces.s};
	padding-right: ${({ theme }) => theme.spaces.s};
	display: flex;
	justify-content: flex-end;
	height: 100%;
	font-size: 13px;

	@media (min-width: 1200px) {
		padding-left: 0;
		padding-right: 0;
		min-width: 1200px;
		max-width: 1200px;
		margin: 0 auto;
	}
`

export const Logout = styled.span`
	text-decoration: underline;
	cursor: pointer;
	margin-left: 5px;
`
